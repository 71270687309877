<template>
  <div id="company-list">
    <!-- regi;ar total card -->
    <v-row class="mb-5">
      <v-col v-for="total in totalLocal" :key="total.total" cols="12" sm="6" md="3">
        <v-card>
          <v-card-text class="d-flex align-center justify-space-between pa-4">
            <div>
              <h2 class="font-weight-semibold mb-1">
                {{ total.total }}
              </h2>
              <span>{{ total.title }}</span>
            </div>

            <v-avatar
              :color="resolveTotalIcon(total.title).color"
              :class="`v-avatar-light-bg ${resolveTotalIcon(total.title).color}--text`"
            >
              <v-icon size="25" :color="resolveTotalIcon(total.title).color" class="rounded-0">
                {{ resolveTotalIcon(total.title).icon }}
              </v-icon>
            </v-avatar>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <!-- list filters -->
    <v-card>
      <v-card-title> Empresas </v-card-title>

      <!-- actions -->
      <v-card-text class="d-flex align-center flex-wrap pb-0">
        <!-- search -->
        <v-text-field
          v-model="searchQuery"
          placeholder="Buscar"
          outlined
          hide-details
          dense
          class="company-search me-3 mb-4"
        >
        </v-text-field>

        <v-spacer></v-spacer>

        <div class="d-flex align-center flex-wrap">
          <v-btn color="primary" class="mb-4 me-3" :to="{ name: 'apps-company-view' }" v-if="$can('create', 'company')">
            <v-icon>{{ icons.mdiPlus }}</v-icon>
            <span>Agregar Empresa</span>
          </v-btn>

          <v-btn color="secondary" outlined class="mb-4" @click="exportCompanies">
            <v-icon size="17" class="me-1">
              {{ icons.mdiExportVariant }}
            </v-icon>
            <span>Exportar</span>
          </v-btn>
        </div>
      </v-card-text>

      <!-- table -->
      <v-data-table
        v-model="selectedRows"
        :headers="tableColumns"
        :items="companiesListTable"
        :options.sync="options"
        :footer-props="{
          'items-per-page-text': 'Empresas por página: ',
          'items-per-page-options': [15, 30, 50, 100],
        }"
        :server-items-length="totalCompanyListTable"
        loading-text="Cargando empresas..."
        :loading="loading"
        show-select
      >
        <!-- logo -->
        <template #[`item.logo`]="{ item }">
          <div class="d-flex align-center">
            <router-link
              :to="{ name: 'apps-company-view', params: { id: item._id } }"
              class="text--primary font-weight-semibold text-truncate cursor-pointer text-decoration-none"
            >
              <v-avatar
                :color="item.logo ? '' : 'primary'"
                :class="item.logo ? '' : 'v-avatar-light-bg primary--text text-md-body-1'"
                size="60"
              >
                <v-img
                  v-if="item.logo"
                  :src="$store.state.apidomain + '/company/logos/' + item.logo + '?rand=' + rand"
                ></v-img>
                <span v-else class="font-weight-medium">{{ avatarText(item.name) }}</span>
              </v-avatar>
            </router-link>
          </div>
        </template>

        <!-- defaultContact -->
        <template #[`item.defaultContact`]="{ item }">
          <div class="d-flex align-center" v-if="item.contactData.length">
            <router-link
              :to="{ name: 'apps-user-view', params: { id: item.defaultContact } }"
              class="text--primary font-weight-semibold text-truncate cursor-pointer text-decoration-none"
            >
              <span>{{ item.contactData.at(0).name }} {{ item.contactData.at(0).lastname }}</span>
            </router-link>
          </div>
        </template>

        <!-- actions -->
        <template #[`item.actions`]="{ item }">
          <v-menu bottom left>
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon v-bind="attrs" v-on="on">
                <v-icon>{{ icons.mdiDotsVertical }}</v-icon>
              </v-btn>
            </template>

            <v-list>
              <v-list-item :to="{ name: 'apps-company-view', params: { id: item._id } }">
                <v-list-item-title>
                  <v-icon size="20" class="me-2">
                    {{ icons.mdiFileDocumentOutline }}
                  </v-icon>
                  <span>Detalle</span>
                </v-list-item-title>
              </v-list-item>

              <v-list-item @click="deleteItem(item._id)" v-if="$can('delete', 'company')">
                <v-list-item-title>
                  <v-icon size="20" class="me-2">
                    {{ icons.mdiDeleteOutline }}
                  </v-icon>
                  <span>Eliminar</span>
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </template>
        <template v-slot:footer.page-text="{ pageStart, pageStop, itemsLength }">
          {{ pageStart }}-{{ pageStop }} de {{ itemsLength }}
        </template>
        <template v-slot:no-data> No hay empresas para mostrar </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import store from '@/store'
import {
  mdiAccountOutline,
  mdiDeleteOutline,
  mdiDotsVertical,
  mdiExportVariant,
  mdiFileDocumentOutline,
  mdiPlus,
  mdiSquareEditOutline,
} from '@mdi/js'
import { onUnmounted, ref } from 'vue'

// sidebar
import { avatarText } from '@core/utils/filter'
import companyStoreModule from '../companyStoreModule'
import useCompaniesList from './useCompaniesList'

export default {
  components: {},
  setup() {
    const appCompany = 'app-company'

    // Register module
    if (!store.hasModule(appCompany)) store.registerModule(appCompany, companyStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(appCompany)) store.unregisterModule(appCompany)
    })

    const rand = ref(Date.now())

    const {
      companiesListTable,
      tableColumns,
      searchQuery,
      exportCompanies,
      totalCompanyListTable,
      loading,
      options,
      totalLocal,
      selectedRows,
      fetchCompanies,
      deleteCompany,
      resolveTotalIcon,
    } = useCompaniesList()

    const deleteItem = async item => {
      const didConfirm = await store.dispatch('alertConfirm/CONFIRM', {
        title: '¿Esta seguro que desea borrarlo?',
        message: 'No podra recuperarlo',
      })
      if (didConfirm) {
        deleteCompany(item)
      }
    }

    return {
      rand,
      exportCompanies,
      deleteItem,
      companiesListTable,
      tableColumns,
      searchQuery,
      totalCompanyListTable,
      loading,
      options,
      totalLocal,
      selectedRows,
      avatarText,
      resolveTotalIcon,
      fetchCompanies,

      // icons
      icons: {
        mdiSquareEditOutline,
        mdiFileDocumentOutline,
        mdiDotsVertical,
        mdiDeleteOutline,
        mdiPlus,
        mdiExportVariant,
        mdiAccountOutline,
      },
    }
  },
}
</script>

<style lang="scss">
@import '@core/preset/preset/apps/company.scss';
</style>
